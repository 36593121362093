import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'dialog-filing-route',
  templateUrl: 'dialog-filing-route.component.html',
  styleUrls: ['dialog-filing-route.component.css']
})
export class FilingRouteDialogComponent {
  constructor(public dialogRef: MatDialogRef<FilingRouteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: FilingRouteDialogData) { }

  onClickCancel(): void {
    this.dialogRef.close();
  }
}


export interface FilingRouteDialogData {
  dialogTitle: string;
  dialogMode: string;
  name: string;
}
