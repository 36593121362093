import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RouseService } from '../../rouse.service';
import { StepDialog } from './dialog-step';
import { RouseConfigService } from '../../rouse.config.service';


export interface StepDialogData {
  dialogTitle: string;
  dialogMode: string;

  name: string;
  type: string;
  duration: number;
  unit: string;

  selectionDurations: number[];
  selectionUnits: string[];

  stepTypes: ValueViewValue[];
  units: ValueViewValue[];

  items: any[];
  selectItems: any[];
}

export interface ValueViewValue {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-edit-step',
  templateUrl: 'edit-step.component.html',
  styleUrls: ['edit-step.component.css']
})
export class EditStepComponent implements OnInit {

  countryId: number = 0;
  countries: any[] = [];
  protectionTypeId: number = 0;
  protectionTypes: any[] = [];
  filingRouteId: number = 0;
  filingRoutes: any[] = [];
  technicalFieldId: number = 0;
  technicalFields: any[] = [];
  stepDurationData: any[] = [];

  dirty: boolean = false;

  stepTypes: ValueViewValue[] = [
    { value: 'fixed', viewValue: 'Fixed' },
    { value: 'option', viewValue: 'Toggle' },
    { value: 'option2', viewValue: 'Toggle with Selection' }
  ];

  public getStepTypeViewValue(value: string): string {
    let stepTypes = this.stepTypes.filter(x => x.value === value);
    if (stepTypes.length > 0) {
      return stepTypes[0].viewValue;
    }
    return "";
  }

  getDurationValue(step: any): any {
    if (step.type !== 'option2') {
      return step.duration.true.values[step.duration.true.selectedValueIndex].value;
    }
    else {
      return step.duration.false.values[step.duration.true.selectedValueIndex].value;
    }
  }

  getDurationUnit(step: any): any {
    if (step.type !== 'option2') {
      return step.duration.true.values[step.duration.true.selectedValueIndex].unit;
    }
    else {
      return step.duration.false.values[step.duration.true.selectedValueIndex].unit;
    }
  }

  units: ValueViewValue[] = [
    { value: 'days', viewValue: 'Day(s)' },
    { value: 'months', viewValue: 'Month(s)' },
    { value: 'years', viewValue: 'Year(s)' }
  ];

  constructor(public dialog: MatDialog, private rouseService: RouseConfigService) { }

  ngOnInit() {
    this.initialize();
  }

  async initialize(): Promise<void> {
    this.countries = await this.rouseService.getCountries();
    if (this.countries.length > 0) {
      this.countryId = this.countries[0].id;
      //this.technicalFields = await this.rouseService.getAllTechnicalFields();
      //if (this.technicalFields.length > 0) {
      //  this.technicalFieldId = this.technicalFields[0].id;
      //}
      await this.loadCountryData();
    }
  }

  async loadCountryData(): Promise<void> {
    this.protectionTypeId = 0;
    this.filingRoutes = [];
    this.filingRouteId = 0;
    this.technicalFieldId = 0;
    this.technicalFields = [];
    this.stepDurationData = [];
    this.dirty = false;

    this.protectionTypes = await this.rouseService.getProtectionTypes(this.countryId);
    if (this.protectionTypes.length > 0) {
      this.protectionTypeId = this.protectionTypes[0].id;
      this.filingRoutes = await this.rouseService.getFilingRoutes(this.countryId, this.protectionTypeId);
      if (this.filingRoutes.length > 0) {
        this.filingRouteId = this.filingRoutes[0].id;
        this.technicalFields = await this.rouseService.getTechnicalFields(this.countryId, this.protectionTypeId, this.filingRouteId);
        if (this.technicalFields.length > 0) {
          this.technicalFieldId = this.technicalFields[0].id;
          this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
        }
      }
    }
  }

  async loadProtectionTypeData(): Promise<void> {
    this.filingRoutes = await this.rouseService.getFilingRoutes(this.countryId, this.protectionTypeId);
    if (this.filingRoutes.length > 0) {
      this.filingRouteId = this.filingRoutes[0].id;
      this.technicalFields = await this.rouseService.getTechnicalFields(this.countryId, this.protectionTypeId, this.filingRouteId);
      if (this.technicalFields.length > 0) {
        this.technicalFieldId = this.technicalFields[0].id;
        this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
      }
    }
    else {
      this.filingRouteId = 0;
    }
    this.dirty = false;
  }

  async loadFilingRouteData(): Promise<void> {
    this.technicalFields = await this.rouseService.getTechnicalFields(this.countryId, this.protectionTypeId, this.filingRouteId);
    if (this.technicalFields.length > 0) {
      this.technicalFieldId = this.technicalFields[0].id;
      this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
    }
    this.dirty = false;
  }

  async loadStepDuration(countryId: number, protectionTypeId: number, filingRouteId: number, technicalFieldId: number): Promise<any[]> {
    if (countryId !== 0 && protectionTypeId !== 0 && filingRouteId !== 0 && technicalFieldId !== 0) {
      return await this.rouseService.getStepDuration(countryId, protectionTypeId, filingRouteId, technicalFieldId);
    }
    return [];
  }

  async onCountryChanged(event: MatSelectChange): Promise<void> {
    this.countryId = event.value;
    await this.loadCountryData();
  }

  async onProtectionTypeChanged(event: MatSelectChange): Promise<void> {
    this.protectionTypeId = event.value;
    await this.loadProtectionTypeData();
  }

  async onFilingRouteChanged(event: MatSelectChange): Promise<void> {
    this.filingRouteId = event.value;
    await this.loadFilingRouteData();
  }

  async onTechnicalFieldChanged(event: MatSelectChange): Promise<void> {
    this.technicalFieldId = event.value;
    this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
    this.dirty = false;
  }

  async save() {
    if (this.countryId !== 0 && this.protectionTypeId !== 0 && this.filingRouteId !== 0 && this.technicalFieldId !== 0) {
      const stepDurationModel = {
        countryId: this.countryId,
        protectionTypeId: this.protectionTypeId,
        filingRouteId: this.filingRouteId,
        technicalFieldId: this.technicalFieldId,
        data: JSON.stringify(this.stepDurationData)
      };
      const ret = await this.rouseService.postStepDuration(stepDurationModel);
      if (ret === 'OK')
        this.dirty = false;
    }
  }

  //discard(): void {
  //  this.loadFilingRouteData();
  //}

  addStep(): void {
    const items: ValueViewValue[] = this.buildControlItems("-2");
    const selectItems = [];

    const dialogRef = this.dialog.open(StepDialog, {
      width: '550px',
      data: {
        dialogTitle: 'Add new step',
        dialogMode: 'add',
        name: 'step',
        type: this.stepTypes[0].value,
        duration: 1,
        unit: 'months',
        stepTypes: this.stepTypes,
        units: this.units,
        enabledBy: "-1",
        items: items,
        selectItems: selectItems
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result');
      console.log(result);

      let selected: boolean = false;
      let duration: any = {};
      if (result.type === this.stepTypes[2].value) {
        let v = [];
        let n = [];
        for (let item of result.selectItems) {
          v.push({
            value: item.duration,
            unit: item.unit
          });
          n.push(item.name)
        }

        duration = {
          true: {
            selectedValueIndex: 0,
            values: v,
            valueNames: n
          },
          false: {
            selectedValueIndex: 0,
            values: [
              {
                value: result.duration,
                unit: result.unit
              }
            ],
            valueNames: ['']
          }
        };
      }
      else if (result.type === this.stepTypes[1].value) {
        duration = {
          true: {
            selectedValueIndex: 0,
            values: [
              {
                value: result.duration,
                unit: result.unit
              }
            ]
          },
          false: {
            selectedValueIndex: 0,
            values: [
              {
                value: 0,
                unit: result.unit
              }
            ]
          }
        }
      }
      else {
        selected = true;
        duration = {
          true: {
            selectedValueIndex: 0,
            values: [
              {
                value: result.duration,
                unit: result.unit
              }
            ]
          }
        };
      }

      let step = {
        name: result.name,
        type: result.type,
        selected: selected,
        enabledBy: result.enabledBy,
        duration: duration
      }
      this.stepDurationData.push(step);

      this.dirty = true;
    });
  }

  buildControlItems(ignoreIdx: string): ValueViewValue[] {
    let items: ValueViewValue[] = [{
      value: "-1", viewValue: "---"
    }];
    for (let i in this.stepDurationData) {
      if (i !== ignoreIdx && this.stepDurationData[i].type === 'option') {
        items.push({ value: i, viewValue: this.stepDurationData[i].name });
      }
    }
    return items;
  }

  editStep(idx: string, step: any): void {
    let dialogRef = null;
    if (step.type === this.stepTypes[2].value) {
      let values = step.duration.true.values;
      let names = step.duration.true.valueNames;
      let selectItems = [];
      for (let i in values) {
        selectItems.push({
          name: names[i],
          duration: values[i].value,
          unit: values[i].unit
        });
      }

      dialogRef = this.dialog.open(StepDialog, {
        width: '550px',
        data: {
          dialogTitle: 'Edit step',
          dialogMode: 'edit',
          name: step.name,
          type: step.type,
          duration: step.duration.false.values[0].value,
          unit: step.duration.false.values[0].unit,
          stepTypes: this.stepTypes,
          units: this.units,
          enabledBy: step.enabledBy,
          items: [],
          selectItems: selectItems
        }
      });
    }
    else if (step.type === this.stepTypes[1].value) {
      let items: ValueViewValue[] = this.buildControlItems(idx);

      dialogRef = this.dialog.open(StepDialog, {
        width: '550px',
        data: {
          dialogTitle: 'Edit step',
          dialogMode: 'edit',
          name: step.name,
          type: step.type,
          duration: step.duration.true.values[0].value,
          unit: step.duration.true.values[0].unit,
          stepTypes: this.stepTypes,
          units: this.units,
          enabledBy: step.enabledBy,
          items: items
        }
      });
    }
    else {
      dialogRef = this.dialog.open(StepDialog, {
        width: '550px',
        data: {
          dialogTitle: 'Edit step',
          dialogMode: 'edit',
          name: step.name,
          type: step.type,
          duration: step.duration.true.values[0].value,
          unit: step.duration.true.values[0].unit,
          stepTypes: this.stepTypes,
          units: this.units,
          items: []
        }
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        step.name = result.name;
        if (step.type === this.stepTypes[0].value) {
          step.duration.true.values[0].value = result.duration;
          step.duration.true.values[0].unit = result.unit;
        }
        else if (step.type === this.stepTypes[1].value) {
          step.duration.true.values[0].value = result.duration;
          step.duration.true.values[0].unit = result.unit;
          step.enabledBy = result.enabledBy;

          let idx = this.stepDurationData.findIndex((v) => { return v === step; });
          if (typeof idx !== 'undefined') {
            if (result.enabledBy > -1) {
              this.stepDurationData[result.enabledBy].enableStepIndex = idx;
            }
          }
          else {
            if (result.enabledBy > -1) {
              this.stepDurationData[result.enabledBy].enableStepIndex = -1;
            }
          }
        }
        else {
          let v = [];
          let n = [];
          for (let item of result.selectItems) {
            v.push({
              value: item.duration,
              unit: item.unit
            });
            n.push(item.name)
          }

          step.duration = {
            true: {
              selectedValueIndex: 0,
              values: v,
              valueNames: n
            },
            false: {
              selectedValueIndex: 0,
              values: [
                {
                  value: result.duration,
                  unit: result.unit
                }
              ],
              valueNames: ['']
            }
          };
        }

        this.dirty = true;
        //type: result.type,
        //selected: true,
        //duration: {
        //    true: {
        //        selectedValueIndex: 0,
        //        values: [
        //            {
        //                value: result.duration,
        //                unit: result.unit
        //            }
        //        ]
        //    }
        //}
      }
    });
  }

  deleteStep(idx: number): void {
    this.openDeleteConfirmationDialog(idx);
  }

  moveStepUp(idx: number): void {
    if (idx > 0) {
      let step0 = this.stepDurationData[idx - 1];
      this.stepDurationData[idx - 1] = this.stepDurationData[idx];
      this.stepDurationData[idx] = step0;
      this.dirty = true;
    }
  }

  moveStepDown(idx: number): void {
    if (idx < this.stepDurationData.length - 1) {
      let step0 = this.stepDurationData[idx + 1];
      this.stepDurationData[idx + 1] = this.stepDurationData[idx];
      this.stepDurationData[idx] = step0;
      this.dirty = true;
    }
  }

  openDeleteConfirmationDialog(deleteIndex: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to delete this step?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stepDurationData.splice(deleteIndex, 1);
        this.dirty = true;
      }
    });
  }
}
