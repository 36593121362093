import { Component } from '@angular/core';
import { UserService } from '../../user.service';
import { UserProfileDialogComponent } from '../../user/profile/userprofile.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdatePasswordDialogComponent } from '../../user/profile/update-password.component';


@Component({
  selector: 'app-nav-admin--menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavAdminMenuComponent {
  isExpanded = false;
  userFullName = '';

  constructor(private userService: UserService,
    public dialog: MatDialog) {
    this.userFullName = localStorage.getItem('email');
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }


  async updateUserPassword(): Promise<void> {
    const dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Update User Password',
        dialogMode: 'update',
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  async updateUserProfile(): Promise<void> {
    await this.userService.getUserProfile().then(user => {
      const dialogRef = this.dialog.open(UserProfileDialogComponent, {
        width: '550px',
        data: {
          dialogTitle: 'Update User Profile',
          dialogMode: 'update',
          user: user
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
    );
  }

}
