import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'Rouse App';

  constructor() { }

  //getUser() {
  //  this.msalService.getCurrentUserInfo();
  //}

  //logout() {
  //  this.msalService.logout();
  //} 
}
