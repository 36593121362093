
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../user.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {
  isExpanded: boolean = false;
  email: string;

  constructor(public service: UserService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.service.formModel.reset();
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }

  }
  
  onSubmit() {
    this.spinner.show();
    this.service.forgotPassword(this.email).subscribe(
      (res: any) => {
        console.log(res);
        if (res == 202) {
          this.spinner.hide();
          this.router.navigateByUrl('/forgot-password-confirmation');
        }
        else {
          this.spinner.hide();
          res.errors.forEach(element => {
            var result = this.toastr.error(element.description, 'Sending Email Failed.');

          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

}
