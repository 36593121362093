import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleChartComponent } from 'angular-google-charts';
import { PrintService } from '../print.service';

@Component({
  selector: 'app-ppc',
  templateUrl: './ppc.component.html',
  styleUrls: ['./ppc.component.css']
})
export class PpcComponent implements OnInit {

  ppcDetails: any;
  timelineImage: string = '';
  singaporeLogo: string = '';

  constructor(route: ActivatedRoute, private printService: PrintService) {
    let b = atob(route.snapshot.params['ppcDetails']);
    this.ppcDetails = JSON.parse(b);
    this.timelineImage = this.ppcDetails.timelineImage;
    this.singaporeLogo = this.ppcDetails.singaporeLogo;
  }

  ngOnInit() {
    this.printService.onDataReady();
  }

  showSingaporeContent(): boolean {
    return this.ppcDetails.country == 'Singapore';
  }
}
