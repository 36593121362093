import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RouseConfigService } from '../../rouse.config.service';
import { UserService } from '../../user.service';
import { UserDialogComponent } from './dialog-user.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})

export class EditUserComponent {
  selectedColumns = new FormControl(['email','firstName','lastName','orgName','emailConfirmed','status','role','lastLoginDate']);
  columns: any[] = [
    { name: 'email', text: 'Email', checked: '', classname: '' },
    { name: 'firstName', text: 'First Name', checked: '', classname: '' },
    { name: 'lastName', text: 'Last Name', checked: '', classname: '' },
    { name: 'orgName', text: 'Organization', checked: '', classname: '' },
    { name: 'emailConfirmed', text: 'Email Confirmed', checked: '', classname: '' },
    { name: 'status', text: 'Status', checked: '', classname: '' },
    { name: 'role', text: 'Role', checked: '', classname: '' },
    { name: 'lastLoginDate', text: 'Last Login Date', checked: '', classname: '' }
  ];
  users: any[] = [];
  dirty: boolean = false;

  constructor(private rouseService: RouseConfigService, public dialog: MatDialog, private userService: UserService) {
    this.initialize();
  }
  roles: string[] = [
    'Admin', 'User' 
  ];

  async initialize(): Promise<void> {
    this.users = await this.rouseService.getUsers();
    this.setColumnNames();
  }

  async export() {
    //Populate data table
    var str = `<div><table border='1px'><tr>
                <td bgcolor='#91f089'>Email</td>
                <td bgcolor='#91f089'>First Name</td>
                <td bgcolor='#91f089'>Last Name</td>
                <td bgcolor='#91f089'>Organization</td>
                <td bgcolor='#91f089'>Email Confirmed</td>
                <td bgcolor='#91f089'>Status</td>
                <td bgcolor='#91f089'>Role</td>
                <td bgcolor='#91f089'>Last Login Date</td>
              </tr>`;
    for (var x = 0; x < this.users.length; x++) {
      str += "<tr>";
      for (var i = 0; i < 8; i++) {
        if (i == 0)
          str += `<td>${this.users[x].email}</td>`;
        else if (i == 1)
          str += `<td>${this.users[x].firstName}</td>`;
        else if (i == 2)
          str += `<td>${this.users[x].lastName}</td>`;
        else if (i == 3)
          str += `<td>${this.users[x].orgName}</td>`;
        else if (i == 4)
          str += `<td>${this.users[x].emailConfirmed}</td>`;
        else if (i == 5)
          str += `<td>${this.users[x].status}</td>`;
        else if (i == 6)
          str += `<td>${this.users[x].role}</td>`;
        else if (i == 7)
          str += `<td>${this.users[x].lastLoginDate}</td>`;
      }
      str += "</tr>";
    }
    str+="</table></div>"

    var uri = 'data:application/vnd.ms-excel,' + escape(str);

    var downloadLink = document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = "patempo-users.xls";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  addUser() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Add new User',
        dialogMode: 'add',
        email: '',
        firstName: '',
        lastName: '',
        orgName: '',
        role: '',
        roles: this.roles,
        active: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.users.push({
          id: "0",
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          orgName: result.orgName,
          role: result.role,
          active: result.active,
          status: result.active == true ? "Active" : "Inactive",
          emailConfirmed: "Confirmed",
          isChanged: true,
          lastLoginDate: null,
          password: null
        });
        this.dirty = true;
      }
    });
  }

  editUser(index: number) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Edit User',
        dialogMode: 'edit',
        email: this.users[index].email,
        firstName: this.users[index].firstName,
        lastName: this.users[index].lastName,
        orgName: this.users[index].orgName,
        role: this.users[index].role,
        roles: this.roles,
        active: this.users[index].active,
        isChangePassword: false,
        newPassword: ''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.users[index].firstName = result.firstName;
        this.users[index].lastName = result.lastName;
        this.users[index].orgName = result.orgName;
        this.users[index].role = result.role;
        this.users[index].isChanged = true;
        this.users[index].active = result.active;
        this.users[index].status = result.active == true ? "Active" : "Inactive";
        this.users[index]["isChangePassword"] = result.isChangePassword;
        this.users[index]["newPassword"] = result.newPassword;
        this.dirty = true;
      }
    });
  }

  deleteUser(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to delete this user?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.users.splice(index, 1);
        this.dirty = true;
      }
    });
  }

  toggleColumn(columnName: string) {
    const col = this.columns.find(cname => cname.name === columnName);
    if (col) {
      col.checked = col.checked === 'checked' ? '' : 'checked';
      col.classname = col.checked === 'checked' ? '' : 'col-hide'
    }
  }

  getColumnName(columnName: string) {
    const col = this.columns.find(cname => cname.name === columnName);
    if (col)
      return col.text;
    else
      return columnName;
  }

  setColumnNames() {
    if (this.users.length > 0) {
      const user = this.users[0];
      for (const uprop in user) {
        if (Object.prototype.hasOwnProperty.call(user, uprop)) {
          const column = this.columns.find(p => p.name === uprop);
          if (column) {
            column.checked = 'checked';
          }
        }
      }
    }
  }

  async save() {
    const ret = await this.rouseService.updateUsers(this.users);
    if (ret === "OK") {
      this.dirty = false;
      await this.initialize();
    }
  }

  discard() {
    this.initialize();
    this.dirty = false;
  }
}
