import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RouseConfigService } from '../../rouse.config.service';
import { CountryDialogComponent } from './dialog-country.component';

@Component({
  selector: 'app-edit-country',
  templateUrl: './edit-country.component.html',
  styleUrls: ['./edit-country.component.css']
})


export class EditCountryComponent {
  countries: any[] = [];
  dirty: boolean = false;

  constructor(private rouseService: RouseConfigService, public dialog: MatDialog) {
    this.initialize();
  }

  async initialize(): Promise<void> {
    this.countries = await this.rouseService.getCountries();


  }

  addCountry() {
    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Add new country',
        dialogMode: 'add',
        name: 'country ' + (this.countries.length + 1).toString()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.countries.push({
        name: result.name,
        id: 0
      });
      this.dirty = true;
    });
  }

  editCountry(index: number) {
    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Edit country',
        dialogMode: 'edit',
        name: this.countries[index].name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.countries[index].name = result.name;
      this.dirty = true;
    });
  }

  deleteCountry(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to delete this country?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.countries.splice(index, 1);
        this.dirty = true;
      }
    });
  }

  async save() {
    const ret = await this.rouseService.updateCountries(this.countries);
    if (ret === "OK")
      this.dirty = false;
  }

  discard() {
    this.initialize();
    this.dirty = false;
  }
}
