import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StepDialogData } from "./edit-step.component";

@Component({
    selector: 'dialog-step',
    templateUrl: 'dialog-step.html',
    styleUrls: ['dialog-step.css']
})
export class StepDialog {

    constructor(
        public dialogRef: MatDialogRef<StepDialog>,
        @Inject(MAT_DIALOG_DATA) public data: StepDialogData) { }

    onClickCancel(): void {
        this.dialogRef.close();
    }

    addSubStep(): void {
        this.data.selectItems.push({
            name: 'item' + this.data.selectItems.length.toString(),
            duration: 1,
            unit: 'months'
        })
    }

    deleteSubStep(idx: number): void {
        this.data.selectItems.splice(idx, 1);
    }
}
