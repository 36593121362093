
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../user.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styles: []
})
export class RegistrationComponent implements OnInit {
  isExpanded: boolean = false;

  constructor(public service: UserService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.service.formModel.reset();
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }

  }
  toasterHandler() {
    this.router.navigateByUrl('/login');
  }

  onSubmit() {
    this.spinner.show();
    this.service.register().subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.service.formModel.reset();
          var result = this.toastr.success('Please check email for activation.', 'Registration successful.');
          this.spinner.hide();
          result.onHidden
            .pipe(take(1))
            .subscribe(() => this.toasterHandler());

          result.onTap
            .pipe(take(1))
            .subscribe(() => this.toasterHandler());

        } else {
          this.spinner.hide();
          res.errors.forEach(element => {
            switch (element.code) {
              case 'DuplicateUserName':
                this.toastr.error('Email is already taken','Registration failed.');
                break;

              default:
              this.toastr.error(element.description,'Registration failed.');
                break;
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

}
