import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'dialog-user',
  templateUrl: 'dialog-user.component.html',
  styleUrls: ['dialog-user.component.css']
})
export class UserDialogComponent {
  constructor(public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData) { }

  onClickCancel(): void {
    this.dialogRef.close();
  }
  onValidatePassword(password): any {
    if (/^(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password))
      return true;
    return false;
  }
}


export interface UserDialogData {
  dialogTitle: string;
  dialogMode: string;
  email: string;
  firstName: string;
  lastName: string;
  orgName: string;
  roles: string[];
  active: boolean;
}
