import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { RouseConfigService } from '../../rouse.config.service';


export class RouseItemNode {
  name: string;
  id: number;
  parent: RouseItemNode;
  children: RouseItemNode[];
  level: number;
  checked: boolean;
}


@Injectable()
export class RouseItemDatabase {
  dataChange = new BehaviorSubject<RouseItemNode[]>([]);
  get data(): RouseItemNode[] { return this.dataChange.value; }

  treeData: RouseItemNode[] = [];

  constructor(private rouseService: RouseConfigService) {
    this.initialize();
  }

  async initialize(): Promise<void> {
    const allCountries = await this.rouseService.getCountries();
    const allProtectionTypes = await this.rouseService.getProtectionTypes(0);
    const allFillingRoutes = await this.rouseService.getFilingRoutes(0, 0);
    const allTechnicalFields = await this.rouseService.getAllTechnicalFields2();

    for (const country of allCountries) {
      const node0: RouseItemNode = { name: country.name, id: country.id, parent: null, children: [], level: 0, checked: true };
      this.treeData.push(node0);
      const countryChildren = this.treeData[this.treeData.length - 1].children;

      for (const protectionType of allProtectionTypes) {
        const p = await this.rouseService.getProtectionType(country.id, protectionType.id);

        const node1: RouseItemNode = {
          name: protectionType.name,
          id: protectionType.id,
          parent: node0,
          children: [],
          level: 1,
          checked: (p !== null) ? true : false
        };
        countryChildren.push(node1);

        const protectionTypeChildren = countryChildren[countryChildren.length - 1].children;

        for (const fillingRoute of allFillingRoutes) {
          const f = await this.rouseService.getFilingRoute(country.id, protectionType.id, fillingRoute.id);

          const protectionTypeNode = {
            name: fillingRoute.name,
            id: fillingRoute.id,
            parent: node1,
            children: [],
            level: 2,
            checked: (f !== null) ? true : false
          };
          protectionTypeChildren.push(protectionTypeNode);

          const technicalFieldChildren = protectionTypeChildren[protectionTypeChildren.length - 1].children;
          for (const technicalField of allTechnicalFields) {
            const tExists = await this.rouseService.getTechnicalField(country.id, protectionType.id, fillingRoute.id, technicalField.id);

            technicalFieldChildren.push({
              name: technicalField.name,
              id: technicalField.id,
              parent: protectionTypeNode,
              children: [],
              level: 3,
              checked: (tExists !== null) ? true : false
            });
          }
        }
      }
    }

    this.dataChange.next(this.treeData);
  }

}


@Component({
  selector: 'app-edit-map',
  templateUrl: './edit-map.component.html',
  styleUrls: ['./edit-map.component.css']
})
export class EditMapComponent {
  // tslint:disable-next-line:member-ordering
  // tslint:disable-next-line:no-inferrable-types
  dirty: boolean = false;

  treeControl = new NestedTreeControl<RouseItemNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<RouseItemNode>();
  hasChild = (_: number, node: RouseItemNode) => !!node.children && node.children.length > 0;

  constructor(private _database: RouseItemDatabase, private rouseService: RouseConfigService) {
    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  itemSelectionToggle(node: RouseItemNode): void {
    node.checked = !node.checked;
    this.dirty = true;
    if (node.checked) {
      if (node.parent !== null) {
        this.applyCheckToParent(node.parent);
      }
      if (node.children.length > 0) {
        this.applyCheckToChildren(node);
      }
    } else {
      if (node.parent !== null) {
        this.applyUncheckToParent(node.parent);
      }
      if (node.children.length > 0) {
        this.applyUncheckToChildren(node);
      }
    }
  }

  applyCheckToParent(node: RouseItemNode): void {
    node.checked = true;
    if (node.parent !== null) {
      this.applyCheckToParent(node.parent);
    }
  }

  applyCheckToChildren(node: RouseItemNode): void {
    for (let i = 0; i < node.children.length; i++) {
      node.children[i].checked = true;
      if (node.children[i].children.length > 0) {
        this.applyCheckToChildren(node.children[i]);
      }
    }
  }

  applyUncheckToParent(node: RouseItemNode): void {
    let hasChecked: boolean;
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].checked)
        hasChecked = true;
    }
    if (!hasChecked)
      node.checked = false;
    if (!hasChecked && node.parent !== null) {
      this.applyUncheckToParent(node.parent);
    }
  }

  applyUncheckToChildren(node: RouseItemNode): void {
    for (let i = 0; i < node.children.length; i++) {
      node.children[i].checked = false;
      if (node.children[i].children.length > 0) {
        this.applyUncheckToChildren(node.children[i]);
      }
    }
  }

  async save() {
    const keys: any[] = [];
    const data = this.dataSource.data;
    // tslint:disable-next-line:forin
    for (const i in data) {
      const countryId = data[i].id;
      const countryChildren = data[i].children;

      for (const j in countryChildren) {
        if (countryChildren[j].checked) {
          const protectionTypeId = countryChildren[j].id;
          const protectionTypeChildren = countryChildren[j].children;

          for (const k in protectionTypeChildren) {
            if (protectionTypeChildren[k].checked) {
              const filingRouteId = protectionTypeChildren[k].id;
              const technicalFieldChildren = protectionTypeChildren[k].children;

              for (const t in technicalFieldChildren) {
                if (technicalFieldChildren[t].checked) {
                  const technicalFieldId = technicalFieldChildren[t].id;

                  keys.push({
                    countryId: countryId,
                    protectionTypeId: protectionTypeId,
                    filingRouteId: filingRouteId,
                    technicalFieldId: technicalFieldId
                  });

                }
              }
            }
          }
        }
      }
    }

    const ret = await this.rouseService.updateStepDurationKeys(keys);
    if (ret === "OK")
      this.dirty = false;
  }

  discard() {
    this.dirty = false;
  }
}
