import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ProtectionTypeDialogComponent } from './dialog-protection-type.component';
import { RouseConfigService } from '../../rouse.config.service';

@Component({
  selector: 'app-edit-protection-type',
  templateUrl: './edit-protection-type.component.html',
  styleUrls: ['./edit-protection-type.component.css']
})
export class EditProtectionTypeComponent {
  protectionTypes: any[] = [];
  dirty: boolean = false;

  constructor(private rouseService: RouseConfigService, public dialog: MatDialog) {
    this.initialize();
  }

  async initialize(): Promise<void> {
    this.protectionTypes = await this.rouseService.getProtectionTypes(0);
  }

  addProtectionType() {
    const dialogRef = this.dialog.open(ProtectionTypeDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Add new protection type',
        dialogMode: 'add',
        name: 'protection type ' + (this.protectionTypes.length + 1).toString()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.protectionTypes.push({
        name: result.name,
        id: 0
      });
      this.dirty = true;
    });
  }

  editProtectionType(index: number) {
    const dialogRef = this.dialog.open(ProtectionTypeDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Edit protection type',
        dialogMode: 'edit',
        name: this.protectionTypes[index].name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.protectionTypes[index].name = result.name;
      this.dirty = true;
    });
  }

  deleteProtectionType(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to delete this protection type?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.protectionTypes.splice(index, 1);
        this.dirty = true;
      }
    });
  }

  async save() {
    const ret = await this.rouseService.updateProtectionTypes(this.protectionTypes);
    if (ret === "OK")
      this.dirty = false;
  }

  discard() {
    this.initialize();
    this.dirty = false;
  }
}
