
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-confirmation',
  templateUrl: './forgot-password-confirmation.component.html',
  styles: []
})
export class ForgotPasswordConfirmationComponent implements OnInit {
  isExpanded: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }

  }
  
}
