import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private fb: FormBuilder, private http: HttpClient) { }
  readonly BaseApiUri = document.baseURI !== undefined && document.baseURI !== null ? `${document.baseURI}api/` : `api/`; //environment.baseApiUrl;
  formModel = this.fb.group({
    Email: ['', [Validators.email, Validators.required]],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    OrgName: ['', Validators.required],
    Passwords: this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(4)]],
      ConfirmPassword: ['', Validators.required]
    }, { validator: this.comparePasswords })

  });
  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('ConfirmPassword');
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('Password').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

  register() {
    var body = {
      Email: this.formModel.value.Email,
      FirstName: this.formModel.value.FirstName,
      LastName: this.formModel.value.LastName,
      OrgName: this.formModel.value.OrgName,
      Password: this.formModel.value.Passwords.Password
    };
    return this.http.post(this.BaseApiUri + 'ApplicationUser/Register', body);
  }

  forgotPassword(email) {
    
    var userProfile = {
      Email: email
    }

    return this.http.post(this.BaseApiUri + 'ApplicationUser/SendForgotPasswordEmail', userProfile);
  }

  validateResetPasswordToken(userId, code, newPassword) {
    var token = {
      UserId: userId,
      Code: code,
      NewPassword: newPassword
    }
    return this.http.post(this.BaseApiUri + 'ApplicationUser/ResetPassword', token);
  }

  validateEmailToken(userId, code) {
    var token = {
      UserId: userId,
      Code: code,
    }
    return this.http.post(this.BaseApiUri + 'ApplicationUser/VerifyEmail', token);
  }

  updateUserProfile(user) {
    var userProfile = {
      FirstName: user.firstName,
      LastName: user.lastName,
      OrgName: user.orgName
    }

    return this.http.post(this.BaseApiUri + 'ApplicationUser/UpdateUserProfile', userProfile);
  }

  updateUserPassword(user) {

    var userProfile = {
      Password: user.password,
      NewPassword: user.newPassword
    }

    return this.http.post(this.BaseApiUri + 'ApplicationUser/UpdateUserPassword', userProfile);
  }

  login(formData) {
    return this.http.post(this.BaseApiUri + 'ApplicationUser/Login', formData);
  }

  logout() {

    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',

      })
    };
    return this.http.post(this.BaseApiUri + 'ApplicationUser/Logout', httpOptions);
  }

  async getUserProfile(): Promise<any> { 
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',

      })
    };
    const email = localStorage.getItem('email');

    let user = null;

    await this.http.get<any>(this.BaseApiUri + `ApplicationUser/GetUserProfile/${email}`, httpOptions)
      .toPromise()
      .then(data => {
        user = data;
    });
    return user;
      
  }


  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    if (localStorage.getItem('token') !== null) {
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      var userRole = payLoad.role;

      allowedRoles.forEach(element => {
        if (userRole == element) {
          isMatch = true;
          return true;
        }
      });
    }
    return isMatch;
  }
}
