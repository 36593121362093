import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'dialog-protection-type',
    templateUrl: 'dialog-technical-field.component.html',
    styleUrls: ['dialog-technical-field.component.css']
})
export class TechnicalFieldDialogComponent {
    constructor(public dialogRef: MatDialogRef<TechnicalFieldDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: TechnicalFieldDialogData) { }

    onClickCancel(): void {
        this.dialogRef.close();
    }
}

export interface TechnicalFieldDialogData {
    dialogTitle: string;
    dialogMode: string;
    name: string;
}
