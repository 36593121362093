import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'dialog-protection-type',
  templateUrl: 'dialog-protection-type.component.html',
  styleUrls: ['dialog-protection-type.component.css']
})
export class ProtectionTypeDialogComponent {
  constructor(public dialogRef: MatDialogRef<ProtectionTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ProtectionTypeDialogData) { }

  onClickCancel(): void {
    this.dialogRef.close();
  }
}

export interface ProtectionTypeDialogData {
  dialogTitle: string;
  dialogMode: string;
  name: string;
}
