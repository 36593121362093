import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

//Material
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { NgxSpinnerModule } from "ngx-spinner";

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GoogleChartsModule } from 'angular-google-charts';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PpcComponent } from './ppc/ppc.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { PrintService } from './print.service';
import { RouseService } from './rouse.service';
import { RouseConfigService } from './rouse.config.service';

//Config
import { ConfigHomeComponent } from './config/home/home.component'
import { EditUserComponent } from './config/edit-user/edit-user.component'
import { CountryDialogComponent } from './config/edit-country/dialog-country.component'
import { UserDialogComponent } from './config/edit-user/dialog-user.component'
import { EditCountryComponent } from './config/edit-country/edit-country.component'
import { ConfirmationDialogComponent } from './config/confirmation-dialog/confirmation-dialog.component'
import { FilingRouteDialogComponent } from './config/edit-filing-route/dialog-filing-route.component';
import { EditFilingRouteComponent } from './config/edit-filing-route/edit-filing-route.component';
import { EditMapComponent, RouseItemDatabase } from './config/edit-map/edit-map.component';
import { ProtectionTypeDialogComponent } from './config/edit-protection-type/dialog-protection-type.component';
import { EditProtectionTypeComponent } from './config/edit-protection-type/edit-protection-type.component';
import { StepDialog } from './config/edit-step/dialog-step';
import { EditStepComponent } from './config/edit-step/edit-step.component';
import { TechnicalFieldDialogComponent } from './config/edit-technical-field/dialog-technical-field.component';
import { EditTechnicalFieldComponent } from './config/edit-technical-field/edit-technical-field.component';
import { ForgotPasswordComponent } from './user/profile/forgot-password.component';

import { NavAdminMenuComponent } from './config/nav-menu/nav-menu.component';

import { environment } from '../environments/environment';
import { UserComponent } from './user/user.component';
import { RegistrationComponent } from './user/registration/registration.component';
import { UserProfileDialogComponent } from './user/profile/userprofile.component';
import { UpdatePasswordDialogComponent } from './user/profile/update-password.component';
import { LoginComponent } from './user/login/login.component';
import { LogoutComponent } from './user/logout/logout.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { UserService } from './user.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth/auth.guard';
import { ResetPasswordComponent } from './user/profile/reset-password.component';
import { ForgotPasswordConfirmationComponent } from './user/profile/forgot-password-confirmation.component';
import { EmailConfirmationComponent } from './user/profile/email-confirmation.component';

import { AboutComponent } from './about/about.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'D MMMM YYYY',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

//export const protectedResourceMap: any =
//  [
//    [environment.baseUrl, environment.scopeUri],
//    [environment.baseApiUrl + 'getcountries', environment.scopeUri]
//  ];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    NavAdminMenuComponent,
    HomeComponent,
    PpcComponent,
    PrintLayoutComponent,
    ConfirmationDialogComponent,
    CountryDialogComponent,
    UserDialogComponent,
    EditCountryComponent,
    EditUserComponent,
    EditProtectionTypeComponent,
    ProtectionTypeDialogComponent,
    EditFilingRouteComponent,
    FilingRouteDialogComponent,
    EditTechnicalFieldComponent,
    TechnicalFieldDialogComponent,
    EditMapComponent,
    EditStepComponent,
    StepDialog,
    ConfigHomeComponent,
    UserComponent,
    RegistrationComponent,
    UserProfileDialogComponent,
    UpdatePasswordDialogComponent,
    LoginComponent,
    LogoutComponent,
    AdminPanelComponent,
    ForbiddenComponent,
    ForgotPasswordComponent,
    ForgotPasswordConfirmationComponent,
    ResetPasswordComponent,
    EmailConfirmationComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      progressBar: true,
      preventDuplicates: true,
      positionClass: 'toast-top-right',
    }),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
          { path: 'ppc/:ppcDetails', component: PpcComponent }
        ]
      },
      { path: 'config', component: ConfigHomeComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-step', component: EditStepComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-country', component: EditCountryComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-user', component: EditUserComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-map', component: EditMapComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-protection-type', component: EditProtectionTypeComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-filing-route', component: EditFilingRouteComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'config/edit-technical-field', component: EditTechnicalFieldComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin'] } },
      { path: 'registration', component: RegistrationComponent },
      { path: 'login', component: LoginComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'forgot-password-confirmation', component: ForgotPasswordConfirmationComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'verify-email', component: EmailConfirmationComponent },
      { path: 'about', component: AboutComponent },
    ]),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    MatCheckboxModule,
    MatTreeModule,
    GoogleChartsModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule
  ],
  providers: [
    RouseService,
    RouseConfigService,
    PrintService,
    RouseItemDatabase,
    //MsalUserService,
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: MsalInterceptor,
    //  multi: true
    //},
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    UserService, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    StepDialog,
    ConfirmationDialogComponent,
    CountryDialogComponent,
    UserDialogComponent,
    ProtectionTypeDialogComponent,
    FilingRouteDialogComponent,
    TechnicalFieldDialogComponent,
    UserProfileDialogComponent,
    UpdatePasswordDialogComponent
  ]
})
export class AppModule { }

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}


//https://www.c-sharpcorner.com/article/easily-enable-azure-ad-authentication-in-angular-and-web-api-core-app/
