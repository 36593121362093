import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class ConfigHomeComponent implements OnInit {


  constructor() {
  }

  async ngOnInit(): Promise<void> {
   
  }


}
