import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TechnicalFieldDialogComponent } from './dialog-technical-field.component';
import { RouseConfigService } from '../../rouse.config.service';

@Component({
  selector: 'app-edit-technical-field',
  templateUrl: './edit-technical-field.component.html',
  styleUrls: ['./edit-technical-field.component.css']
})
export class EditTechnicalFieldComponent {

    technicalFields: any[] = [];
    dirty: boolean = false;

  constructor(private rouseService: RouseConfigService, public dialog: MatDialog) {
        this.initialize();
    }

    async initialize(): Promise<void> {
      this.technicalFields = await this.rouseService.getAllTechnicalFields(0);
    }

    addTechnicalField() {
        const dialogRef = this.dialog.open(TechnicalFieldDialogComponent, {
            width: '550px',
            data: {
                dialogTitle: 'Add new technical field',
                dialogMode: 'add',
                name: 'technical field ' + (this.technicalFields.length + 1).toString()
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.technicalFields.push({
                name: result.name,
                id: 0
            });
            this.dirty = true;
        });
    }

    editTechnicalField(index: number) {
        const dialogRef = this.dialog.open(TechnicalFieldDialogComponent, {
            width: '550px',
            data: {
                dialogTitle: 'Edit technical field',
                dialogMode: 'edit',
                name: this.technicalFields[index].name
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.technicalFields[index].name = result.name;
            this.dirty = true;
        });
    }

    deleteTechnicalField(index: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: "Are you sure you want to delete this technical field?"
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.technicalFields.splice(index, 1);
                this.dirty = true;
            }
        });
    }

    async save() {
      const ret = await this.rouseService.updateTechnicalFields(this.technicalFields);
      if (ret === "OK")
        this.dirty = false;
    }

    discard() {
        this.initialize();
        this.dirty = false;
    }
}
