import { Component, OnInit } from "@angular/core";
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileDialogComponent } from '../user/profile/userprofile.component';
import { UpdatePasswordDialogComponent } from '../user/profile/update-password.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  isExpanded: boolean = false;
  userFullName = '';
  showConfiguration: boolean = true;
  constructor(private userService: UserService, public dialog: MatDialog) {
    this.userFullName = localStorage.getItem('email');
  }

  async ngOnInit(): Promise<void> {
    this.showConfiguration = this.userService.roleMatch(['Admin']);
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }
  }

  async updateUserProfile(): Promise<void> {
    await this.userService.getUserProfile().then(user => {
      const dialogRef = this.dialog.open(UserProfileDialogComponent, {
        width: '550px',
        data: {
          dialogTitle: 'Update User Profile',
          dialogMode: 'update',
          user: user
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
    );
  }

  async updateUserPassword(): Promise<void> {
    const dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Update User Password',
        dialogMode: 'update',
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
