import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "../../user.service";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";

@Component({
  selector: 'dialog-updatepassword',
  templateUrl: 'update-password.component.html'
})
export class UpdatePasswordDialogComponent {
  currentPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";

  constructor(public dialogRef: MatDialogRef<UpdatePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    private userSerive: UserService,
    private toastr: ToastrService) {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  toasterHandler() {
    this.dialogRef.close();
  }

  onClickSave(): void {
    if (this.newPassword !== this.confirmPassword) {
      this.toastr.error('Confirm Password does not match.', 'Password update failed.');
    }
    else if (this.newPassword.length < 8) {
      this.toastr.error('Password must be of minimum 8 characters length.', 'Password update failed.');
    }
    else {
      var userProfile = {
        password: this.currentPassword,
        newPassword: this.confirmPassword
      }

      this.userSerive.updateUserPassword(userProfile).subscribe((res: any) => {
        if (res.succeeded) {
          var result = this.toastr.success('Record Saved', 'Password update success');
          this.dialogRef.close();

          //result.onHidden
          //  .pipe(take(1))
          //  .subscribe(() => this.toasterHandler());

          //result.onTap
          //  .pipe(take(1))
          //  .subscribe(() => this.toasterHandler());
        }
        else {
          res.errors.forEach(element => {
            this.toastr.error(element.description, 'Password update failed.');
          });
        }
      });
    }

  } 
}


export interface UserDialogData {
  dialogTitle: string;
  dialogMode: string;
}

