import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "../../user.service";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";

@Component({
  selector: 'dialog-userprofile',
  templateUrl: 'userprofile.component.html'
})
export class UserProfileDialogComponent {
  constructor(public dialogRef: MatDialogRef<UserProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    private userSerive: UserService,
    private toastr: ToastrService,
  ) {
  }

  toasterHandler() {
    this.dialogRef.close();
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickSave(): void {

    this.userSerive.updateUserProfile(this.data.user).subscribe(t => {
      const result = this.toastr.success('Record Saved', 'User Profile Successfully Saved');
      this.dialogRef.close();
      //result.onHidden
      //  .pipe(take(1))
      //  .subscribe(() => this.toasterHandler());

      //result.onTap
      //  .pipe(take(1))
      //  .subscribe(() => this.toasterHandler());

    });

  } 
}


export interface UserDialogData {
  dialogTitle: string;
  dialogMode: string;
  user: any;
}

