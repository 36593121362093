import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  formModel = {
    UserName: '',
    Password: ''
  }
  isExpanded: boolean = false;
  isAdmin = false;

  constructor(private service: UserService,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService) {

    this.isAdmin = this.userService.roleMatch(['Admin']);
  }

  ngOnInit() {
    this.service.logout().subscribe(res => {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
    });
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }
  }

  onSubmit(form: NgForm) {
    this.spinner.show();
    this.service.login(form.value).subscribe(
      (res: any) => {
        if (res.url != null && res.url != undefined) {
          window.location.href = res.url;
        }
        else {
          localStorage.setItem('token', res.token);
          localStorage.setItem('email', form.value.UserName);
          this.spinner.hide();
          this.router.navigateByUrl('/');
        }
      },
      err => {
        this.spinner.hide();
        if (err.status == 404) {
          this.toastr.error('Incorrect username or password.', 'Authentication failed.');
        }
        else if (err.status == 400) {
          this.toastr.error('Please check your email for activation link.', 'Email is not yet activated.');
        }
        else
          console.log(err);
      }
    );
  }
}
