import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import * as saveSvgAsPng from 'save-svg-as-png';
import { PrintService } from '../print.service';
import { RouseService } from '../rouse.service';
import { UserService } from '../user.service';
//import { Router } from '@angular/router';
//import * as $ from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileDialogComponent } from '../user/profile/userprofile.component';
import { UpdatePasswordDialogComponent } from '../user/profile/update-password.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userFullName = '';
  form: FormGroup = new FormGroup({
    matterCode: new FormControl(''),
    clientRef: new FormControl(''),
    country: new FormControl(''),
    protectionType: new FormControl(''),
    filingRoute: new FormControl(''),
    technicalField: new FormControl(''),
    filingDate: new FormControl('')
  });

  matterCode: string;
  clientRef: string;
  countries: any[] = [];
  protectionTypes: any[] = [];
  filingRoutes: any[] = [];
  technicalFields: any[] = [];
  stepDurationData: any[] = [];
  estimatedGrantDate: string = '';
  showConfiguration: boolean = true;
  isExpanded: boolean = false;
  singaporeLogoPath = window.location.href + "resources/AmicaLaw Logo GBg.png";

  constructor(private rouseService: RouseService,
    private printService: PrintService,
    private userService: UserService,
    public dialog: MatDialog) {

    this.userFullName = localStorage.getItem('email');
  }

  async ngOnInit(): Promise<void> {

    this.showConfiguration = this.userService.roleMatch(['Admin']);
    
    if (typeof Storage != 'undefined') {

      let sessionMatterCode = sessionStorage.getItem('matterCode');
      if (sessionMatterCode === null) {
        this.matterCode = '';
      }
      else {
        this.matterCode = sessionMatterCode;
      }

      let sessionClientRef = sessionStorage.getItem('clientRef');
      if (sessionClientRef === null) {
        this.clientRef = '';
      }
      else {
        this.clientRef = sessionClientRef;
      }

      let sessionCountries = sessionStorage.getItem('countries');
      if (sessionCountries === null) {
        this.countries = await this.rouseService.getCountries();
      }
      else {
        this.countries = JSON.parse(sessionCountries);
      }
      this.countries = this.countries.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

      let sessionCountry = sessionStorage.getItem('country');
      if (sessionCountry === null) {
        this.countryId = 0;
        this.form.controls['country'].setValue('');
      }
      else {
        this.countryId = Number(sessionCountry);
        this.form.controls['country'].setValue(sessionCountry);
      }

      let sessionProtectionTypes = sessionStorage.getItem('protectionTypes');
      if (sessionProtectionTypes === null) {
        this.protectionTypes = await this.rouseService.getProtectionTypes(this.countryId);
      }
      else {
        this.protectionTypes = JSON.parse(sessionProtectionTypes);
      }

      let sessionProtectionType = sessionStorage.getItem('protectionType');
      if (sessionProtectionType === null) {

        this.protectionTypeId = 0;
        this.form.controls['protectionType'].setValue('');
      }
      else {
        this.form.controls['protectionType'].setValue(sessionProtectionType);
      }

      let sessionFilingRoutes = sessionStorage.getItem('filingRoutes');
      if (sessionFilingRoutes === null) {
        this.filingRoutes = await this.rouseService.getFilingRoutes(this.countryId, this.protectionTypeId);
      }
      else {
        this.filingRoutes = JSON.parse(sessionFilingRoutes);
      }

      let sessionFilingRoute = sessionStorage.getItem('filingRoute');
      if (sessionFilingRoute === null) {
        this.filingRouteId = 0;
        this.form.controls['filingRoute'].setValue('');
      }
      else {
        this.form.controls['filingRoute'].setValue(sessionFilingRoute);
      }

      let sessionTechnicalFields = sessionStorage.getItem('technicalFields');
      if (sessionTechnicalFields === null) {
        this.technicalFields = await this.rouseService.getTechnicalFields(this.countryId, this.protectionTypeId, this.filingRouteId);
      }
      else {
        this.technicalFields = JSON.parse(sessionTechnicalFields);
      }

      let sessionTechnicalField = sessionStorage.getItem('technicalField');
      if (sessionTechnicalField === null) {
        this.technicalFieldId = 0;
        this.form.controls['technicalField'].setValue('');
      }
      else {
        this.form.controls['technicalField'].setValue(sessionTechnicalField);
      }

      let sessionFilingDate = sessionStorage.getItem('filingDate');
      if (sessionFilingDate === null) {
        this.form.controls['filingDate'].setValue(moment().startOf('day'));
      }
      else {
        this.form.controls['filingDate'].setValue(moment(sessionFilingDate));
      }

      let sessionEstimatedGrantDate = sessionStorage.getItem('estimatedGrantDate');
      if (sessionEstimatedGrantDate === null) {
      }
      else {
        this.estimatedGrantDate = sessionEstimatedGrantDate;
      }

      let sessionStepDurationData = sessionStorage.getItem('stepDurationData');
      if (sessionStepDurationData === null) {
        this.loadStepDurationData();
      }
      else {
        let stepDurationData = JSON.parse(sessionStepDurationData);
        for (let step of stepDurationData) {
          step.result0 = moment(step.result0);
          step.result1 = moment(step.result1);
        }

        this.stepDurationData = stepDurationData;
      }

      let sessionTimeline = sessionStorage.getItem('timeline');
      if (sessionTimeline === null) {
      }
      else {
        this.timeline = JSON.parse(sessionTimeline);
        let timelineData = this.timeline.data;
        for (let data of timelineData) {
          data[2] = new Date(data[2]);
          data[3] = new Date(data[3]);
        }
      }

      sessionStorage.clear();
    }

    
  }

  async loadCountryData(): Promise<void> {
    this.protectionTypeId = 0;
    this.filingRoutes = [];
    this.filingRouteId = 0;
    this.stepDurationData = [];
    this.technicalFieldId = 0;
    this.technicalFields = [];

    this.protectionTypes = await this.rouseService.getProtectionTypes(this.countryId);
    if (this.protectionTypes.length > 0) {
      this.protectionTypeId = this.protectionTypes[0].id;
      this.filingRoutes = await this.rouseService.getFilingRoutes(this.countryId, this.protectionTypeId);
      if (this.filingRoutes.length > 0) {
        this.filingRouteId = this.filingRoutes[0].id;
        this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
        this.buildFormControls();
        this.calculateAllStepDurations();
      }
    }
  }

  async loadProtectionTypeData(): Promise<void> {
    this.filingRoutes = await this.rouseService.getFilingRoutes(this.countryId, this.protectionTypeId);
    if (this.filingRoutes.length > 0) {
      this.filingRouteId = this.filingRoutes[0].id;

      this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
    }
    else {
      this.filingRouteId = 0;
    }
  }

  async loadFilingRouteData(): Promise<void> {
    this.stepDurationData = await this.loadStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
  }

  async loadStepDuration(countryId: number, protectionTypeId: number, filingRouteId: number, technicalFieldId: number): Promise<any[]> {
    if (countryId !== 0 && protectionTypeId !== 0 && filingRouteId !== 0 && technicalFieldId !== 0) {
      return await this.rouseService.getStepDuration(countryId, protectionTypeId, filingRouteId, technicalFieldId);
    }
    return [];
  }

  countryId: number = 0;
  protectionTypeId: number = 0;
  filingRouteId: number = 0;
  technicalFieldId: number = 0;

  async loadStepDurationData(): Promise<void> {
    if (this.countryId !== 0 && this.protectionTypeId !== 0 && this.filingRouteId !== 0 && this.technicalFieldId !== 0) {
      this.stepDurationData = await this.rouseService.getStepDuration(this.countryId, this.protectionTypeId, this.filingRouteId, this.technicalFieldId);
      this.buildFormControls();
      this.calculateAllStepDurations();
    }
  }

  async onCountryChanged(event: MatSelectChange): Promise<void> {
    this.countryId = event.value;
    this.protectionTypes = await this.rouseService.getProtectionTypes(this.countryId);
    this.protectionTypeId = 0;
    this.form.controls['protectionType'].setValue('');

    this.filingRoutes = [];
    this.filingRouteId = 0;

    this.technicalFields = [];
    this.technicalFieldId = 0;
  }

  async onProtectionTypeChanged(event: MatSelectChange): Promise<void> {
    this.protectionTypeId = event.value;

    this.filingRoutes = await this.rouseService.getFilingRoutes(this.countryId, this.protectionTypeId);
    this.filingRouteId = 0;
    this.form.controls['filingRoute'].setValue('');

  }

  async onFilingRouteChanged(event: MatSelectChange): Promise<void> {
    this.filingRouteId = event.value;

    this.technicalFields = await this.rouseService.getTechnicalFields(this.countryId, this.protectionTypeId, this.filingRouteId);
    this.technicalFieldId = 0;
    this.form.controls['technicalField'].setValue('');
  }

  async onTechnicalFieldChanged(event: MatSelectChange): Promise<void> {
    this.technicalFieldId = event.value;
    await this.loadStepDurationData();
    this.buildFormControls();
    this.calculateAllStepDurations();
  }

  isNotNormalProcess(type: string): boolean {
    return type !== 'fixed';
  }
  isNormalProcess(type: string): boolean {
    return type === 'fixed';
  }

  buildFormControls() {
    for (let i in this.stepDurationData) {
      let step = this.stepDurationData[i];
      if ('options' in step) {
        for (let j in step.options) {
          let option = step.options[j];
          if ('selectItems' in option) {
            let formControl: FormControl = new FormControl(i + ',' + j + ',0');
            this.form.addControl('select' + i + '-' + j, formControl);
          }
        }
      }
    }
  }

  showFilingDate(): boolean {
    let protectionType = this.form.controls['protectionType'].value;
    let filingRoute = this.form.controls['filingRoute'].value;
    let technicalField = this.form.controls['technicalField'].value;
    return (protectionType !== '' && filingRoute !== '' && technicalField !== '');
  }

  showProtectionType(): boolean {
    let country = this.form.controls['country'].value;
    return (country !== '');
  }

  showFilingRoute(): boolean {
    let protectionType = this.form.controls['protectionType'].value;
    return this.showProtectionType() && (protectionType !== '');
  }

  showTechnicalField(): boolean {
    let filingRoute = this.form.controls['filingRoute'].value;
    return this.showFilingRoute() && (filingRoute !== '');
  }

  showStepDurations(): boolean {
    return (this.form.controls['filingDate'].value !== null);
  }

  onFilingDateChange(event: any) {
    this.calculateAllStepDurations();
  }

  getDatePlaceholder() {
    return this.filingRoutes.find(x => x.id == this.form.controls['filingRoute'].value) != undefined &&
      this.filingRoutes.find(x => x.id == this.form.controls['filingRoute'].value).name == "PCT National Application" ? "National Phase Entry Date" : "Filing Date";
  }

  onSlideToggleChange(ob: MatSlideToggleChange) {
    this.stepDurationData[ob.source.id].selected = !(this.stepDurationData[ob.source.id].selected);

    if (this.stepDurationData[ob.source.id].type === 'option') {
      if (!this.stepDurationData[ob.source.id].selected) {
        if (this.stepDurationData[ob.source.id].enableStepIndex > -1) {
          this.stepDurationData[this.stepDurationData[ob.source.id].enableStepIndex].selected = false;
        }
      }
    }
    this.calculateAllStepDurations();
  }

  onSlideToggleChangeForSelectItem(ob: MatSlideToggleChange, step: any, j: number) {

  }

  // Default return value: false
  isDisabled(step: any) {
    if (step.enabledBy === "-1") {
      return false;
    }

    let controlStep = this.stepDurationData[step.enabledBy];
    return !controlStep.selected;
  }

  // Default return value: true
  getEnabledByOptionIndex(ops: any, op: any): boolean {
    let selected: boolean = true;
    if (typeof op.enabledByOptionIndex !== 'undefined') {
      let refOp = ops[op.enabledByOptionIndex];
      selected = refOp.selected;
    }
    return selected;
  }

  showSelectItem(ob: any): boolean {
    return ('selectItems' in ob);
  }

  calculateAllStepDurations() {
    if (this.form.controls['protectionType'].value === "" || this.form.controls['filingRoute'].value === "") {
      return;
    }

    let result0 = moment(this.form.controls['filingDate'].value);
    for (let step of this.stepDurationData) {
      step.result0 = moment(result0);

      let duration = step.duration[step.selected].values[step.duration[step.selected].selectedValueIndex];
      step.result1 = result0.add(duration.value, duration.unit);

      //if (step.selected) {
      //    let duration = step.duration.true.values[step.duration.true.selectedValueIndex];
      //    step.result1 = result0.add(duration.value, duration.unit);
      //}

      result0 = moment(step.result1);
    }

    let date = result0.format('D MMMM YYYY');
    this.estimatedGrantDate = (date === 'Invalid date') ? "–" : date;

    // Build timeline data
    //
    this.timeline.data = [];
    this.timeline.options.colors = [];

    //let lastFixedIdx = 0;
    let num = 1;
    result0 = moment(this.form.controls['filingDate'].value);
    for (let step of this.stepDurationData) {
      let defaultValue = step.duration[step.selected].values[step.duration[step.selected].selectedValueIndex];
      if (defaultValue.value > 0) {
        let result1 = moment(result0);
        result1.add(defaultValue.value, defaultValue.unit);

        //this.timeline.data.push([num.toString(), step.name, result0.toDate(), result1.toDate(), 'tooltip ' + step.name]);
        let addName = '';
        if (step.type === 'option2') {
          addName = ' ' + step.duration[step.selected].valueNames[step.duration[step.selected].selectedValueIndex];
        }

        this.timeline.data.push([step.name + addName, result0.format('D MMM YYYY') + " - " + result1.format('D MMM YYYY'), result0.toDate(), result1.toDate()]);
        if (step.type === 'fixed') {
          this.timeline.options.colors.push('green');
        }
        else if (step.type === 'option2') {
          if (step.selected) {
            this.timeline.options.colors.push('lightgreen');
          }
          else {
            this.timeline.options.colors.push('green');
          }

        }
        else {
          this.timeline.options.colors.push('orange');
        }
        num++;
        result0 = moment(result1);
      }
      else if (typeof step.duration.selectItems !== 'undefined' && step.duration.selectItems.length > 0) {

      }
    }

    let chartHeight = (num * 46 < 50) ? 50 : num * 46;
    this.timeline.options.height = chartHeight;
    this.refresh();
  }

  refresh() {
    this.setSessionVariables();
    this.ngOnInit();
  }

  setSessionVariables() {
    sessionStorage.setItem('matterCode', this.form.controls['matterCode'].value);
    sessionStorage.setItem('clientRef', this.form.controls['clientRef'].value);
    sessionStorage.setItem('countries', JSON.stringify(this.countries));
    sessionStorage.setItem('country', this.form.controls['country'].value);
    sessionStorage.setItem('protectionTypes', JSON.stringify(this.protectionTypes));
    sessionStorage.setItem('protectionType', this.form.controls['protectionType'].value);
    sessionStorage.setItem('filingRoutes', JSON.stringify(this.filingRoutes));
    sessionStorage.setItem('filingRoute', this.form.controls['filingRoute'].value);
    sessionStorage.setItem('technicalFields', JSON.stringify(this.technicalFields));
    sessionStorage.setItem('technicalField', this.form.controls['technicalField'].value);
    sessionStorage.setItem('filingDate', this.form.controls['filingDate'].value.format());
    sessionStorage.setItem('stepDurationData', JSON.stringify(this.stepDurationData));
    sessionStorage.setItem('estimatedGrantDate', this.estimatedGrantDate);
    sessionStorage.setItem('timeline', JSON.stringify(this.timeline));
  }

  errorMessage: any = {};
  public hasError(controlName: string): boolean {
    let errors = this.form.controls[controlName].errors;
    if (errors !== null) {
      if (errors['matDatepickerParse']) {
        this.errorMessage[controlName] = 'Invalid date input';
        return true;
      }
      else if (errors['required']) {
        this.errorMessage[controlName] = 'The field is required';
        return true;
      }
    }
    return false;
  }

  onSelectChanged(ev: MatSelectChange) {
    this.calculateAllStepDurations();
  }

  showDurationInfo(step: any): boolean {
    return step.selected;
  }

  showSingaporeContent(): boolean {
    let form = JSON.parse(JSON.stringify(this.form.value));
    const country = this.countries.find(x => x.id == form.country);
    form.country = (typeof country !== 'undefined') ? country.name : '';
    return form.country == 'Singapore';
  }

  async getTimelineImage(): Promise<string> {
    let timelineImage: string = '';

    let elem = document.getElementById('timelineChart');
    let svgElement = elem.getElementsByTagName("svg")[0];
    await saveSvgAsPng.svgAsPngUri(svgElement).then(uri => {
      timelineImage = uri;
    });

    return timelineImage;
  }

  toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }));

  async print(): Promise<void> {
    this.setSessionVariables();
    let form = JSON.parse(JSON.stringify(this.form.value));
    form.matterCode = (form.matterCode === "") ? "-" : form.matterCode;
    form.clientRef = (form.clientRef === "") ? "-" : form.clientRef;
    const country = this.countries.find(x => x.id == form.country);
    form.country = (typeof country !== 'undefined') ? country.name : '';
    const protectionType = this.protectionTypes.find(x => x.id == form.protectionType);
    form.protectionType = (typeof protectionType !== 'undefined') ? protectionType.name : '';
    const filingRoute = this.filingRoutes.find(x => x.id == form.filingRoute);
    form.filingRoute = (typeof filingRoute !== 'undefined') ? filingRoute.name : '';
    const technicalField = this.technicalFields.find(x => x.id == form.technicalField);
    form.technicalField = (typeof technicalField !== 'undefined') ? technicalField.name : '';
    form.filingDate = this.form.value.filingDate.format('D MMMM YYYY');
    form.estimatedGrantDate = this.estimatedGrantDate;
    //form.showTechnicalField = this.showTechnicalField();

    //let timelineData: any[] = [];
    let steps: any[] = [];
    //for (let data of this.timeline.data) {
    //  //timelineData.push([data[0], data[1], data[2], data[3]]);
    //  let val = {
    //    name: data[0],
    //    fromDate: moment(data[2]).format('D MMMM YYYY'),
    //    toDate: moment(data[3]).format('D MMMM YYYY')
    //  };
    //  steps.push(val);
    //}
    for (let data of this.stepDurationData) {
      if (data.selected == true) {
        let val = {
          name: data.name,
          fromDate: moment(data.result0._i).format('D MMMM YYYY'),
          toDate: moment(data.result1._i).format('D MMMM YYYY'),
          isNotNormalProcess: data.type !== 'fixed'
        };
        steps.push(val);
      }
    }
    form['timelineImage'] = await this.getTimelineImage();
    form['steps'] = steps;
    if (this.showSingaporeContent()) {
      this.toDataURL(this.singaporeLogoPath)
        .then(dataUrl => {
          form['singaporeLogo'] = dataUrl;
          this.printService.printDocument('ppc', btoa(JSON.stringify(form)));
        });
    }
    else {
      this.printService.printDocument('ppc', btoa(JSON.stringify(form)));
    }
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }
  }
  onReset() {
    sessionStorage.clear();
    location.reload();
  }



  async updateUserProfile(): Promise<void> {
    await this.userService.getUserProfile().then(user => {
      const dialogRef = this.dialog.open(UserProfileDialogComponent, {
        width: '550px',
        data: {
          dialogTitle: 'Update User Profile',
          dialogMode: 'update',
          user: user
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
    );
  }

  async updateUserPassword(): Promise<void> {
    const dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Update User Password',
        dialogMode: 'update',
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  timeline = {
    data: [],
    columnNames: ['Step', 'Period', 'Start', 'End'],
    options: {
      height: 45,
      timeline: {
        showRowLabels: true,
        groupByRowLabel: false,
        barLabelStyle: { fontSize: 11 }
      },
      colors: [],
      hAxis: {
        format: 'MMM yyyy'
      },
      tooltip: {
          isHtml: true
      }
    }
  };
}
