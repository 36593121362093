import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilingRouteDialogComponent } from './dialog-filing-route.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RouseConfigService } from '../../rouse.config.service';


@Component({
  selector: 'app-edit-filing-route',
  templateUrl: './edit-filing-route.component.html',
  styleUrls: ['./edit-filing-route.component.css']
})
export class EditFilingRouteComponent {
  filingRoutes: any[] = [];
  dirty: boolean = false;

  constructor(private rouseService: RouseConfigService, public dialog: MatDialog) {
    this.initialize();
  }

  async initialize(): Promise<void> {
    this.filingRoutes = await this.rouseService.getFilingRoutes(0, 0);
  }

  addFilingRoute() {
    const dialogRef = this.dialog.open(FilingRouteDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Add new filing route',
        dialogMode: 'add',
        name: 'filing route ' + (this.filingRoutes.length + 1).toString()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filingRoutes.push({
          name: result.name,
          id: 0
        });
        this.dirty = true;
      }
    });
  }

  editFilingRoute(index: number) {
    const dialogRef = this.dialog.open(FilingRouteDialogComponent, {
      width: '550px',
      data: {
        dialogTitle: 'Edit filing route',
        dialogMode: 'edit',
        name: this.filingRoutes[index].name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filingRoutes[index].name = result.name;
        this.dirty = true;
      }
    });
  }

  deleteFilingRoute(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to delete this filing route?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filingRoutes.splice(index, 1);
        this.dirty = true;
      }
    });
  }

  async save() {
    const ret = await this.rouseService.updateFilingRoutes(this.filingRoutes);
    if (ret === "OK")
      this.dirty = false;
  }

  discard() {
    this.initialize();
    this.dirty = false;
  }
}
