import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RouseService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',

    })
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

  }

  async getCountries(): Promise<any[]> {
    let countries = null;

    await this.http.get<any[]>(this.baseUrl + 'api/data/GetCountries', this.httpOptions)
      .toPromise()
      .then(data => {
        countries = data;
      });
    return countries;
  }

  async getAllTechnicalFields(): Promise<any[]> {
    let technicalfields = null;

    await this.http.get<any[]>(this.baseUrl + 'api/data/GetAllTechnicalFields', this.httpOptions)
      .toPromise()
      .then(data => {
        technicalfields = data;
      });
    return technicalfields;
  }

  async getProtectionTypes(countryId: number): Promise<any[]> {
    let protectionTypes: any[] = [];

    await this.http.get<any[]>(this.baseUrl + `api/data/GetProtectionTypes/${countryId}`, this.httpOptions)
      .toPromise()
      .then(data => { protectionTypes = data; });
    return protectionTypes;
  }

  async getProtectionType(countryId: number, protectionTypeId: number): Promise<any> {
    let protectionType = null;
    await this.http.get<any[]>(this.baseUrl + `api/data/GetProtectionType/${countryId}/${protectionTypeId}`, this.httpOptions)
      .toPromise()
      .then(data => { protectionType = data; });
    return protectionType;
  }


  async getFilingRoutes(countryId: number, protectionTypeId: number): Promise<any[]> {
    let filingRoutes: any[] = null;
    await this.http.get<any[]>(this.baseUrl + `api/data/GetFilingRoutes/${countryId}/${protectionTypeId}`)
      .toPromise()
      .then(data => { filingRoutes = data; });
    return filingRoutes;
  }

  async getFilingRoute(countryId: number, protectionTypeId: number, filingRouteId: number): Promise<any> {
    let filingRoute = null;
    await this.http.get<any[]>(this.baseUrl + `api/data/GetFilingRoute/${countryId}/${protectionTypeId}/${filingRouteId}`, this.httpOptions)
      .toPromise()
      .then(data => { filingRoute = data; });
    return filingRoute;
  }


  async getTechnicalFields(countryId: number, protectionTypeId: number, filingRouteId: number): Promise<any[]> {
    let technicalFields: any[] = [];
    await this.http.get<any[]>(this.baseUrl + `api/data/GetTechnicalFields/${countryId}/${protectionTypeId}/${filingRouteId}`, this.httpOptions)
      .toPromise()
      .then(data => { technicalFields = data; });
    return technicalFields;
  }


  async getStepDuration(countryId: number, protectionTypeId: number, filingRouteId: number, technicalFieldId: number): Promise<any[]> {
    let steps: any[] = [];
    await this.http.get<any>(this.baseUrl + 'api/data/GetStepDuration/' + countryId.toString() + '/' + protectionTypeId.toString() + '/' + filingRouteId.toString() + '/' + technicalFieldId.toString())
      .toPromise()
      .then(data => {
        steps = JSON.parse(data.data);
      })
    return steps;
  }


  parseStepDurations(countryData: any, typeOfApplication: string, filingMethod: string): any {
    let k1 = this.camelCase(typeOfApplication);
    let k2 = this.camelCase(filingMethod);
    return countryData[k1][k2].steps;
  }

  camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
}

