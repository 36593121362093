import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class RouseConfigService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',

    })
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private toastr: ToastrService) {
  }

  async getCountries(): Promise<any[]> {
    let countries = null;
    await this.http.get<any[]>(this.baseUrl + 'api/configdata/GetCountries', this.httpOptions)
      .toPromise()
      .then(data => {
        countries = data;
      });
    return countries;
  }

  async updateCountries(countries: any): Promise<string> {
    let retValue: any = ""
    await this.http.post<string>(this.baseUrl + 'api/configdata/UpdateCountries', countries, this.httpOptions)
      .toPromise()
      .then((data) => {
        retValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });;
    return retValue;
  }

  async getProtectionTypes(countryId: number): Promise<any[]> {
    let protectionTypes: any[] = [];

    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetProtectionTypes/${countryId}`, this.httpOptions)
      .toPromise()
      .then(data => { protectionTypes = data; });
    return protectionTypes;
  }

  async getProtectionType(countryId: number, protectionTypeId: number): Promise<any> {
    let protectionType = null;
    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetProtectionType/${countryId}/${protectionTypeId}`, this.httpOptions)
      .toPromise()
      .then(data => { protectionType = data; });
    return protectionType;
  }

  async updateProtectionTypes(protectionTypes: any): Promise<string> {
    let retValue: any = "";
    await this.http.post<string>(this.baseUrl + 'api/configdata/UpdateProtectionTypes', protectionTypes, this.httpOptions)
      .toPromise()
      .then((data) => {
        retValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });;
    return retValue;
  }

  async getFilingRoutes(countryId: number, protectionTypeId: number): Promise<any[]> {
    let filingRoutes: any[] = null;
    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetFilingRoutes/${countryId}/${protectionTypeId}`)
      .toPromise()
      .then(data => { filingRoutes = data; });
    return filingRoutes;
  }

  async getFilingRoute(countryId: number, protectionTypeId: number, filingRouteId: number): Promise<any> {
    let filingRoute = null;
    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetFilingRoute/${countryId}/${protectionTypeId}/${filingRouteId}`, this.httpOptions)
      .toPromise()
      .then(data => { filingRoute = data; });
    return filingRoute;
  }

  async updateFilingRoutes(filingRoutes: any): Promise<string> {
    let retValue: any = "";
    await this.http.post<string>(this.baseUrl + 'api/configdata/UpdateFilingRoutes', filingRoutes, this.httpOptions)
      .toPromise()
      .then((data) => {
        retValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });;
    return retValue;
  }

  async getTechnicalField(countryId: number, protectionTypeId: number, filingRouteId: number, technicalFieldId: number): Promise<any> {
    let technicalField = null;
    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetTechnicalField/${countryId}/${protectionTypeId}/${filingRouteId}/${technicalFieldId}`, this.httpOptions)
      .toPromise()
      .then(data => { technicalField = data; });
    return technicalField;
  }

  async getTechnicalFields(countryId: number, protectionTypeId: number, filingRouteId: number): Promise<any[]> {
    let technicalFields: any[] = [];
    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetTechnicalFields/${countryId}/${protectionTypeId}/${filingRouteId}`, this.httpOptions)
      .toPromise()
      .then(data => { technicalFields = data; });
    return technicalFields;
  }

  async updateTechnicalFields(technicalFields: any): Promise <string> {
    let returnValue: any = "";
    this.http.post<string>(this.baseUrl + 'api/configdata/UpdateTechnicalFields', technicalFields, this.httpOptions)
      .toPromise()
      .then((data) => {
        returnValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });;
    return returnValue;
  }

  async getStepDuration(countryId: number, protectionTypeId: number, filingRouteId: number, technicalFieldId: number): Promise<any[]> {
    let steps: any[] = [];
    await this.http.get<any>(this.baseUrl + 'api/configdata/GetStepDuration/' + countryId.toString() + '/' + protectionTypeId.toString() + '/' + filingRouteId.toString() + '/' + technicalFieldId.toString())
      .toPromise()
      .then(data => {
        if (data && data.data) {
          steps = JSON.parse(data.data);
        }
      })
    return steps;
  }

  async postStepDuration(stepDurationModel: any): Promise<string> {
    let retValue: any = ""
    await this.http.post<string>(this.baseUrl + 'api/configdata/PostStepDuration', stepDurationModel, this.httpOptions)
      .toPromise()
      .then((data) => {
        retValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });
    return retValue;
  }

  parseStepDurations(countryData: any, typeOfApplication: string, filingMethod: string): any {
    let k1 = this.camelCase(typeOfApplication);
    let k2 = this.camelCase(filingMethod);
    return countryData[k1][k2].steps;
  }

  async updateStepDurationKeys(stepDurationKeys: any): Promise<string> {
    let retValue: any = "";
    await this.http.post<string>(this.baseUrl + 'api/configdata/UpdateStepDurationKeys', stepDurationKeys, this.httpOptions)
      .toPromise()
      .then((data) => {
        retValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });
    return retValue;
  }


  async getUsers(): Promise<any[]> {
    let users = null;
    await this.http.get<any[]>(this.baseUrl + 'api/configdata/GetUsers', this.httpOptions)
      .toPromise()
      .then(data => {
        users = data;
      });
    return users;
  }

  async updateUsers(users: any): Promise<string> {
    let retValue: any = "";
    await this.http.post<string>(this.baseUrl + 'api/configdata/UpdateUsers', users, this.httpOptions)
      .toPromise()
      .then((data) => {
        retValue = data;
        this.toastr.success("Successfully saved.");
      }).catch((error) => {
        this.toastr.error(error);
      });;
    return retValue;
  }

  async getAllTechnicalFields(technicalFieldId: number): Promise<any[]> {
    let technicalFields: any[] = [];
    await this.http.get<any[]>(this.baseUrl + `api/configdata/GetAllTechnicalFields/${technicalFieldId}`, this.httpOptions)
      .toPromise()
      .then(data => { technicalFields = data; });
    return technicalFields;
  }

  async getAllTechnicalFields2(): Promise<any[]> {
    let technicalfields = null;

    await this.http.get<any[]>(this.baseUrl + 'api/data/GetAllTechnicalFields', this.httpOptions)
      .toPromise()
      .then(data => {
        technicalfields = data;
      });
    return technicalfields;
  }

  camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
}

