
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styles: []
})
export class EmailConfirmationComponent implements OnInit {
  isExpanded: boolean = false;
  showError: boolean = false;
  showNotification: boolean = false;

  constructor(public service: UserService,
    private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit() {
    const userId = this.route.snapshot.queryParamMap.get('userId');
    const code = this.route.snapshot.queryParamMap.get('code');

    this.service.validateEmailToken(userId, code).subscribe(
      (res: any) => {
        this.showNotification = true;
        if (res.succeeded) {
          this.showError = false;
        }
        else {
          this.showError = true;
          res.errors.forEach(element => {
            var result = this.toastr.error(element.description, 'Verify Email Failed.');
          });
        }
      });

  }

  
  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }

  }
  
}
