import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'dialog-country',
  templateUrl: 'dialog-country.component.html',
  styleUrls: ['dialog-country.component.css']
})
export class CountryDialogComponent {
  constructor(public dialogRef: MatDialogRef<CountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CountryDialogData) { }

  onClickCancel(): void {
    this.dialogRef.close();
  }
}


export interface CountryDialogData {
  dialogTitle: string;
  dialogMode: string;
  name: string;
}
