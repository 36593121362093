
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../user.service';
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: []
})
export class ResetPasswordComponent implements OnInit {
  isExpanded: boolean = false;
  isdisabled: boolean = false;


  constructor(public service: UserService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder) {

       
  }

  formModel = this.fb.group({
    Passwords: this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(8)]],
      ConfirmPassword: ['', Validators.required]
    }, { validator: this.comparePasswords })

  });

  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('ConfirmPassword');
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('Password').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }


  ngOnInit() {
    this.formModel.reset();
  }

  toggle() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    else {
      this.isExpanded = true;
    }

  }
  toasterHandler() {
    this.router.navigateByUrl('/login');
  }
  toasterHandlerResetPassword() {
    this.router.navigateByUrl('/forgot-password');
  }
  onSubmit() {
    this.spinner.show();

    const userId = this.route.snapshot.queryParamMap.get('userId');
    const code = this.route.snapshot.queryParamMap.get('code');

    this.service.validateResetPasswordToken(userId, code, this.formModel.value.Passwords.Password).subscribe(
      (res: any) => {
      if (res.succeeded) {
        this.formModel.reset();
        var result = this.toastr.success('Password Saved.', 'Your password has been reset.');
        this.spinner.hide();
        result.onHidden
          .pipe(take(1))
          .subscribe(() => this.toasterHandler());

        result.onTap
          .pipe(take(1))
          .subscribe(() => this.toasterHandler());
      }
      else {
        //this.formModel.reset();
        this.spinner.hide();
        res.errors.forEach(element => {
          var result = this.toastr.error(element.description, 'Password Reset Failed.');

          //result.onHidden
          //  .pipe(take(1))
          //  .subscribe(() => this.toasterHandlerResetPassword());

          //result.onTap
          //  .pipe(take(1))
          //  .subscribe(() => this.toasterHandlerResetPassword());

        });
      }
    });
  }

}
